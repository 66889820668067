import './App.css';
// import Home from './pages/home';
// import './pages/home/Home';
import Home from './pages/home/Home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Navbar from './components/Navbar';
import OurTeam from './pages/ourTeam/OurTeam';
import { Route, Routes } from 'react-router';
import Services from './pages/services/Services';
import ContactUs from './pages/contactUs/ContactUs';
import CaseStudies from './pages/caseStudies/CaseStudies';
function App() {
  return (
    <div className="App outfit">
      <header className="App-header">
       <div>
          <Navbar/>
          <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/our-team" element={<OurTeam />} />
                <Route path="/services" element={<Services />} />
                <Route path="/case-studies" element={<CaseStudies />} />
                <Route path="/contact-us" element={<ContactUs />} />
            </Routes>
          
       </div>
      </header>
    </div>
  );
}

export default App;
