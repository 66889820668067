import React, { useState } from 'react';
import SubscribeComp from '../../components/SubscribeComp';

function ContactUs() {
    // State for form inputs
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        contactTime: '',
        message: '',
    });

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://precociousstrategies.com/contactUs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok && result.success) {
                alert('Your message has been sent successfully!');
            } else {
                alert('There was a problem sending your message. Please try again.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('An error occurred while sending your message.');
        }
    };

    return (
        <div className='outfit'>
            <div className='background-container' style={{ backgroundImage: "url(assets/TEST.SVG)", overflow:"hidden" }}>
                <div className="centered-text">
                    <h1>Contact Us</h1>
                    <p className='subHeading'>Home | contact us</p>
                </div>
            </div>

            <div className='pad inputAndMapDiv justify-btw ' style={{ flex: 1 }}>
                <div className='pad' style={{ flex: 0.4, marginTop: 40 }}>
                    <div className='headingAboutus'>Get In Touch</div>
                    <div className='gray-txt'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text..</div>

                    <form onSubmit={handleSubmit} className='col'>
                        <input
                            className='inputfield'
                            name='name'
                            placeholder='Name'
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className='inputfield'
                            name='email'
                            type='email'
                            placeholder='Email'
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className='inputfield'
                            name='phone'
                            placeholder='Phone Number'
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        <input
                            className='inputfield'
                            name='contactTime'
                            placeholder='What time you would like us to contact you?'
                            value={formData.contactTime}
                            onChange={handleChange}
                        />
                        <textarea
                            className='inputfieldtextarea'
                            name='message'
                            placeholder='Write Message...'
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                        <button className='btn-blue' style={{ width: '100%' }} type='submit'>Submit</button>
                    </form>

                    <div className='colIcon justify-btw' style={{ marginTop: '4vh' }}>
                        <div className='row'>
                            <div><img src='/assets/phone.svg' alt='phone' /></div>
                            <div className='logodetail'>
                                <div className=''>Phone</div>
                                <div>+98 764 56789</div>
                            </div>
                        </div>
                        <div className='row'>
                            <div><img src='/assets/fax.svg' alt='fax' /></div>
                            <div className='logodetail'>
                                <div>Fax</div>
                                <div>+98 764 56789</div>
                            </div>
                        </div>
                        <div className='row'>
                            <div><img src='/assets/email.svg' alt='email' /></div>
                            <div className='logodetail'>
                                <div>Email</div>
                                <div>info@1234.com</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: '100%', flex: 0.4, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className='blue-container' style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 205, backgroundColor: '#02273A', borderTopRightRadius: 30, borderBottomRightRadius: 30 }}></div>
                    <iframe
                        className="responsive-iframe"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13292.502394959469!2d73.1586468!3d33.6020455!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfebbb57b5e487%3A0x7d62fd27da3cc10!2sOakStreet%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2s!4v1718487553830!5m2!1sen!2s"
                        style={{ border: 0, borderRadius: 10 }}
                        allowFullScreen=""
                        loading="eager"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>

            <SubscribeComp />
        </div>
    );
}

export default ContactUs;
