import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import SubscribeComp from '../../components/SubscribeComp';
import { useNavigate } from 'react-router';
import { ClipLoader } from 'react-spinners';

function CaseStudies() {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);
        fetch(`${URL}/getCaseStudy`)
            .then((res) => res.json())
            .then((data) => {
                console.log(data.caseStudies);
                setData(data.caseStudies);
                setLoading(false);
            })
            .catch((error) => {
                console.log('Error fetching data:', error);
                setLoading(false);
            });
    }, [URL]);

    const filterCards = (category) => {
        setSelectedCategory(category);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        return { day, month };
    };

    const filteredCards = selectedCategory === 'All'
        ? data
        : data.filter(card => {
            const categoryWords = card.category.toLowerCase().split(' ');
            return selectedCategory.toLowerCase().split(' ').every(word => categoryWords.includes(word));
        });

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    return (
        <div className='outfit'>
            <div className='background-container' style={{ backgroundImage: "url(assets/TEST.SVG)", overflow: "hidden" }}>
                <div className="centered-text">
                    <h1>Case Studies</h1>
                    <p className='subHeading'><span onClick={() => navigate('/')}>Home</span> | Case studies</p>
                </div>
            </div>

            <div>
                <div className="tabs pad">
                    {['All', 'Financial', 'Human Resources', 'Solicitory', 'Start Ups', 'Strategy'].map(category => (
                        <button
                            key={category}
                            className={`tab-button ${selectedCategory === category ? 'active' : ''}`}
                            onClick={() => filterCards(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>

                {loading ? (
                    <div className="sweet-loading" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <ClipLoader color={"#000000"} loading={loading} css={override} size={50} />
                    </div>
                ) : (
                    <div className="cards-container pad" style={{ overflow: 'hidden' }}>
                        {filteredCards.map(card => {
                            const formattedDate = formatDate(card.date);
                            return (
                                <div key={card.id} className="card">
                                    <img src={card.image} alt={card.title} className="card-image" />
                                    <div className="caseStudy">
                                        <div className='row justify-btw'>
                                            <div className="subHeading">{card.category}</div>
                                            <span className="date-badge center2">
                                                <div className="date-day">{formattedDate.day}</div>
                                                <div className="date-month">{formattedDate.month}</div>
                                            </span>
                                        </div>

                                        <div style={{ marginTop: -40 }}>
                                            <div className="caseStudyTitle">{card.sub_category}</div>
                                            <div className="cStudyText">{card.details}</div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                                <div className="more">Read More</div>
                                                <img src='/assets/readMoreArrow.webp' alt="Read More Arrow" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <SubscribeComp />
        </div>
    );
}

export default CaseStudies;