import React, { useState } from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SubscribeComp from '../../components/SubscribeComp';
import { useNavigate } from 'react-router';
import TestimonialSlider from '../../components/TestimonialSlider';


function Services() {

  const [selectedCard, setSelectedCard] = useState(null);
  const navigate = useNavigate();
  const cardData = [
    { title: "Strategic Business Development", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    { title: "Global Corporate Liaison", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    { title: "Strategic Investments", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    { title: "Focused Trading & Capitalization", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    { title: "Custom Strategic Development", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    { title: "Business Evaluation & Investment", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry." }
  ];


  const handleCardClick = (index) => {
    setSelectedCard(index);
  };


  return (
    <div className='outfit'>

      <div className='background-container' style={{ backgroundImage: "url(assets/TEST.svg)", overflow:"hidden" }}>
                <div className="centered-text">
                    <h1>Services</h1>
                    <p className='subHeading'><span onClick={()=>navigate('/')}>Home</span> | Services</p>
                </div>
      </div>

      <div className='pad'>
        <div className='subHeading'>At one glance</div>
        <div className='headingAboutus'>All Advantage Services</div>
        <div className='gray-txt '
        >Seize easy opportunities to identify potential value-added activities for beta testing. Address the digital divide with more clickthroughs.</div>

      </div>



      <div className='pad'>
        <div className='praticeAreaMain justify-btw' style={{ flex: 1, marginBottom: 20 }}>
          {cardData.slice(0, 3).map((card, index) => (
            <div
              key={index}
              className='card1'
              style={{ flex: 0.3, backgroundColor: selectedCard === index ? ' #02273A' : '#F9F9F9' ,
                position: 'relative',  // Add relative positioning
                padding: '20px',       // Optional padding for better spacing
                borderRadius: '10px'   // Optional for rounded corners
              }}
              onClick={() => handleCardClick(index)}
            >
              <div className='circle' style={{ backgroundColor: selectedCard === index ? 'white' : ' #02273A' }}>
                {/* <img className='imageCenter' src='/assets/Vector.webp' /> */}
                <img 
                    className='imageCenter' 
                    src={selectedCard === index 
                        ? `/assets/servicesLogo/black${index + 1}.svg` 
                        : `/assets/servicesLogo/white${index + 1}.svg`} 
                />
              </div>
              <div style={{ fontSize: 31, fontWeight: 500, paddingBottom: 10, paddingTop: 10, color: selectedCard === index ? 'white' : ' #02273A' }}>{card.title}</div>
              <div style={{ fontSize: 17, fontWeight: 400, color: selectedCard === index ? 'white' : ' #02273A' }}>{card.description}</div>
              <div style={{
                position: 'absolute',  // Absolutely positioned
                top: '10px',           // Adjust top position as needed
                right: '10px',         // Adjust right position as needed
                fontSize: '48px',      // Large font size for the number
                fontWeight: 'bold',    // Bold font weight
                color: selectedCard === index ? 'rgba(255, 255, 255, 0.1)' : 'rgba(2, 39, 58, 0.1)',  // Color adjustment
                zIndex: '0',           // Ensure it's behind other text
              }}>
                {index + 1 < 10 ? `0${index + 1}` : index + 1}  
              </div>
              {/* {selectedCard === index && <div className='readmore' style={{ color: selectedCard === index ? 'white' : ' #02273A' }}>Read more</div>} */}
            </div>
          ))}
        </div>

        <div className='praticeAreaMain justify-btw' style={{ flex: 1 }}>
          {cardData.slice(3).map((card, index) => (
            
            <div
              key={index + 3}
              className='card1'
              style={{ flex: 0.3, backgroundColor: selectedCard === index + 3 ? ' #02273A' : '#F9F9F9',
                position: 'relative',  
                padding: '20px',       
                borderRadius: '10px'  
            
               }}
             
              onClick={() => handleCardClick(index + 3)}
            >
              <div className='circle' style={{ backgroundColor: selectedCard === index + 3 ? 'white' : ' #02273A' }}>
              <img 
                    className='imageCenter' 
                    src={selectedCard === index +3
                        ? `/assets/servicesLogo/black${index + 4}.svg` 
                        : `/assets/servicesLogo/white${index + 4}.svg`} 
                />              </div>
              <div style={{ fontSize: 31, fontWeight: 500, paddingBottom: 10, paddingTop: 10, color: selectedCard === index + 3 ? ' white' : '#02273A' ,}}>{card.title}</div>
              <div style={{ fontSize: 17, fontWeight: 400, color: selectedCard === index + 3 ? ' white' : '#02273A' }}>{card.description}</div>
              <div style={{
                position: 'absolute',  
                top: '10px',           
                right: '10px',       
                fontSize: '48px',      
                fontWeight: 'bold',    
                color: selectedCard === index +3? 'rgba(255, 255, 255, 0.1)' : 'rgba(2, 39, 58, 0.1)',  // Color adjustment
                zIndex: '0',          
              }}>
                {index + 3 < 10 ? `0${index + 4}` : index + 4}  
              </div>
            </div>
          ))}
        </div>
      </div>


    <TestimonialSlider/>

  <SubscribeComp/>

    </div>

  )
}

export default Services