import React, { useEffect, useRef, useState } from 'react'
import SubscribeComp from '../../components/SubscribeComp';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';

const statsData = [
  { id: 1, label: 'Case Settlement', count: '959+' },
  { id: 2, label: 'Insurance Settlement', count: '250+' },
  { id: 3, label: 'Personal Injury', count: '388+' },
  { id: 4, label: 'Divorce Case', count: '400+' },
];

const StatItem = ({ stat, isSelected, onSelect }) => {
  const handleClick = () => onSelect(stat.id);

  return (
    <div
      className={`stat-item ${isSelected ? 'selected' : ''}`}
      onClick={handleClick}
    >
      <div className='font50 num' style={{ fontWeight: 700 }}>{stat.count}</div>
      <div className='num' style={{ fontSize: 23 }}>{stat.label}</div>
    </div>
  );
};



const servicesData = [
  {
    id: 1,
    title: 'Personal Legal Guidance',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s...',
    iconNotSelected: '/assets/teamLogo/white1.svg',
    iconSelected: '/assets/teamLogo/black1.svg',
  },
  {
    id: 2,
    title: 'Business Strategy Consulting',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s...',
    iconNotSelected: '/assets/teamLogo/white2.svg',
    iconSelected: '/assets/teamLogo/black2.svg',
  },
  {
    id: 3,
    title: 'Litigation Support',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s...',
    iconNotSelected: '/assets/teamLogo/white3.svg',
    iconSelected: '/assets/teamLogo/black3.svg',
  },
];

const ServiceItem = ({ service, isSelected, onSelect }) => {
  const handleClick = () => onSelect(service.id);

  return (
    <div
      className={`service-item ${isSelected ? 'selected' : ''}`}
      onClick={handleClick}
    >
      <div className={`icon-container ${isSelected ? 'selected' : ''}`}>
        <img src={isSelected ? service.iconSelected : service.iconNotSelected} alt={service.title} />
      </div>
      <div className="content">
        <h2>{service.title}</h2>
        <p style={{ color: isSelected ? "#ffffff" : '#AFAFAF', fontWeight: 400 }}>{service.description}</p>
      </div>
    </div>
  );
};

const ProfileCard = ({ name, designation, imageUrl }) => {
  return (
    <div className="profile-card">
      {/* <div className="image-container"> */}
      <img className='imageee' src={imageUrl} alt="profile" />
      {/* </div> */}
      <div className="info">
        <h2>{name}</h2>
        <p>{designation}</p>
      </div>
    </div>
  );
};



function OurTeam() {

  const [selectedId, setSelectedId] = useState(null);
  const [leadership, setLeadership] = useState([]);
  const [loading, setLoading] = useState(true);

  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true);
    fetch(`${URL}/getLeadership`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data.leaderships);
        setLeadership(data.leaderships);
      })
      .catch((error) => {
        console.log('Error fetching data:', error);
      })
      .finally(() => setLoading(false));
  }, [URL])

  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  return (
    <div className='outfit'>

      <div className='background-container' style={{ backgroundImage: "url(assets/TEST.SVG)", overflow: "hidden" }}>
        <div className="centered-text">
          <h1>Our Team</h1>
          <p className='subHeading'>Home | our team</p>
        </div>
      </div>



      <div className='pad aboutUs justify-btw'>
        <img className='responsive-img' src='/assets/aboutUs.webp' />

        <div className='paddingL margin '>
          <div className='subHeading'>About Us</div>
          <div className='headingAboutus'>Mission & Values Of Your Law Consult Firm</div>

          <div className='gray-txt'>Provide professional advice and guidance on legal matters, helping clients understand their rights, obligations, and potential courses.</div>

          <div className='center'>

            <div className="stats-container">
              {statsData.map(stat => (
                <StatItem
                  key={stat.id}
                  stat={stat}
                  isSelected={stat.id === selectedId}
                  onSelect={setSelectedId}
                />
              ))}
            </div>
          </div>

        </div>
      </div>


      <div className='aboutUs pad  justify-btw' style={{ flex: 1 }}>
        <div style={{ flex: 0.5 }}>
          <div className='headingAboutus'>Our Team Offers Customer-Centric Advice To Corporations</div>

          <div className='gray-txt p'>Business Law Consulting provides detailed information about a wide range of business law services, encompassing essential areas such as entity formation, contracts, mergers, acquisitions, and intellectual property. Our dedicated team of seasoned legal professionals brings a wealth of experience across various practice areas, ensuring that you receive comprehensive and specialized assistance for your specific needs. Whether you're starting a new venture, negotiating contracts, or exploring intellectual property strategies, we offer precise and up-to-date guidance tailored to your business requirements.</div>

          <div className='gray-txt p'>At Business Law Consulting, we take pride in our commitment to delivering customer-centric advice. With our team of experts, you can trust us to provide accurate, up-to-date, and effective legal counsel. We understand the complexities of the business world and are equipped to handle various legal aspects, offering reliable solutions that align with your objectives. Whether you need assistance with entity formation, contract drafting, navigating mergers and acquisitions, or safeguarding your intellectual property, our professionals are dedicated to offering tailored and impactful legal guidance that helps your business thrive.</div>

        </div>


        <div style={{ flex: 0.48 }} className="services-container">
          {servicesData.map(service => (
            <ServiceItem
              key={service.id}
              service={service}
              isSelected={service.id === selectedId}
              onSelect={setSelectedId}
            />
          ))}
        </div>
      </div>

      <div className="slider-container">

        <Swiper
          spaceBetween={2}
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
            1920: {
              slidesPerView: 4,
            },
          }}
          navigation
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <img src='assets/image2.webp' alt='Image 1' style={{ width: '100%', height: '100%' }} />
          </SwiperSlide>
          <SwiperSlide>
            <img src='assets/image 7.png' alt='Image 2' style={{ width: '100%', height: '100%' }} />
          </SwiperSlide>
          <SwiperSlide>
            <img src='assets/image 9.png' alt='Image 3' style={{ width: '100%', height: 'auto' }} />
          </SwiperSlide>
          <SwiperSlide>
            <img src='assets/image2.webp' alt='Image 4' style={{ width: '100%', height: 'auto' }} />
          </SwiperSlide>
        </Swiper>

      </div>

      <div className='aboutUs pad'>
        {/* <div> */}
        <img className='responsive-img' src='/assets/image5.webp' />
        {/* </div> */}

        <div className='pad' style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'space-between' }}>
          <div className='headingAboutus'>Our Mission</div>
          <div className=' p  gray-txt'>Business Consulting provides comprehensive information about our business law services, covering vital areas such as entity formation, contracts, mergers, acquisitions, and intellectual property. Our expertise extends to various aspects of legal support, ensuring that you have access to detailed guidance tailored to your specific needs.</div>

          <div className='paddingB'>
            <div className='row' style={{ paddingBottom: '2vh' }}>
              <img className='' src='/assets/tick.webp' />
              <div className='paddingL'>Client-Focused Solution sand Results</div>
            </div>
            <div className='row' style={{ paddingBottom: '2vh' }}>
              <img src='/assets/tick.webp' />
              <div className='paddingL'>Flexible, Value Driven Approach</div>
            </div>
            <div className='row' style={{ paddingBottom: '2vh' }}>
              <img src='/assets/tick.webp' />
              <div className='paddingL'>Warning of updated legal risks for customer</div>
            </div>
            <div className='row' style={{ paddingBottom: '2vh' }}>
              <img src='/assets/tick.webp' />
              <div className='paddingL'>A team of experienced and highly specialized</div>
            </div>
          </div>

          <button className='meetOurLaw'>Meet Our Lawyers</button>
        </div>
      </div>

      <div className='pad'>
        <div className='headingAboutus'>Our Leadership</div>

        {loading ? (
          <div className="sweet-loading" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ClipLoader color={"#000000"} loading={loading} size={50} />
          </div>
        ) : (
          <div className="wrap  row justify-btw" >
            {leadership.map(profile => (
              <ProfileCard
                name={profile.name}
                designation={profile.designation}
                imageUrl={profile.image}
              />
            ))}
          </div>
        )}
      </div>
      <SubscribeComp />
    </div>
  )
}

export default OurTeam

