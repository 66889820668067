import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMagnifyingGlass, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom'; 

function Navbar() {
    const [selected, setSelected] = useState('home');
    const [menuOpen, setMenuOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 750);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Initial check
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
        const path = location.pathname;
        const routeMap = {
           '/': 'home',
            '/our-team': 'our-team',
            '/services': 'services',
            '/case-studies': 'case-studies',
            '/contact-us': 'contact-us'
        };
        setSelected(routeMap[path] || 'home');
    },[location]);


    const handleSelect = (item, path) => {
        setSelected(item);
        setMenuOpen(false);
        setTimeout(() => navigate(path), 0);
    };

    return (
        <div className=''>
            <div className=''>
                <div className='firstDiv row pad' style={{ justifyContent: 'space-between' }}>
                    <div className='firstDiv'>
                        <div className='row' style={{alignItems:'center'}}>
                            {/* <FontAwesomeIcon icon={faPhone} size="lg" /> */}
                            <img src='/assets/phone.svg'/>
                            <span className='paddingL'>+00-123-456-789</span>
                        </div>
                        <div className='row' style={{ marginLeft: '5vh',alignItems:'center' }}>
                            {/* <FontAwesomeIcon icon={faEnvelope} size="lg" /> */}
                            <img src='/assets/email.svg'/>
                            <span className='paddingL'>Info@precocious.com</span>
                        </div>
                    </div>
                    <div>
                        <div className='row'>
                            {/* <div>Select language</div> */}
                            {/* <div className='paddingL' style={{ fontWeight: 400 }}>English UK</div> */}
                        </div>
                    </div>
                </div>

                <div className='center2 bottomMargin'>
                    <div className='navbarLine '></div>
                </div>
            </div>
           
            
            <div className={`pad ${isSmallScreen ? 'navbar' : 'featureServiceMain'}  justify-btw`}>

                <div><img className='' src='/assets/logo.webp' alt='Logo' /></div>
                <div className='hamburger' onClick={() => setMenuOpen(!menuOpen)}>
                    <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} size='2x' />
                </div>
                <div className={`nav-menu ${menuOpen ? 'open' : ''}`}>
                    <div className={`nav-item ${selected === 'home' ? 'selected' : ''}`} onClick={() => handleSelect('home', '/')}>Home</div>
                    <div className={`nav-item ${selected === 'our-team' ? 'selected' : ''}`} onClick={() => handleSelect('our-team', '/our-team')}>Our Team</div>
                    <div className={`nav-item ${selected === 'services' ? 'selected' : ''}`} onClick={() => handleSelect('services','/services')}>Services</div>
                    <div className={`nav-item ${selected === 'case-studies' ? 'selected' : ''}`} onClick={() => handleSelect('case-studies','/case-studies')}>Case Studies</div>
                    <div className={`nav-item ${selected === 'contact-us' ? 'selected' : ''}`} onClick={() => handleSelect('contact-us','/contact-us')}>Contact us</div>
                  
                </div>
                <div className='book2 center'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} size='2x' className="icon" />
                    <div className='paddingL'><button className='btn-blue'>Book a call</button></div>
                </div>

            </div>
        </div>
    );
}

export default Navbar;
