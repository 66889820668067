import React from 'react'
import { Link, useNavigate } from 'react-router-dom'; 

function SubscribeComp() {
    const navigate = useNavigate();

  return (
    <div>
            <div className='subscribeBox praticeAreaMain '  style={{overflow:'hidden'}}>
                    <div className='subText'>Subscribe Newsletters</div>
                    <div className="subscribe-container">
                        <input type="email" placeholder="Enter your email address" className="subscribe-input" />
                        <button className="subscribe-button">Subscribe Now</button>
                    </div>
                </div>


                {/* logos sections bottom */}
                <div className='lastSection pad ' >
                    <div className='row maxW'>
                       <Link className='link' to={'/'} ><div>Home</div></Link> 
                       <Link className='link' to={'/our-team'} ><div>Our Team</div></Link> 
                        <Link className='link' to={'/case-studies'} ><div>Case Studies</div></Link> 
                        <Link className='link' to={'/services'} ><div>Services</div></Link> 
                        <Link className='link' to={'/contact-us'} ><div>Contact Us</div></Link> 

                    </div>

                    <div className='centerIcon'>
                        <div className='paddingR'><img style={{cursor:'pointer'}} className='img-responsive' src='/assets/Facebook.webp' /></div>
                        <div className='paddingR'><img style={{cursor:'pointer'}} className='img-responsive' src='/assets/Instagram.webp' /></div>
                        <div className='paddingR'><img style={{cursor:'pointer'}} className='img-responsive' src='/assets/Twitter.webp' /></div>
                    </div>
                </div>


                {/* bottom line */}
                <div className='center2 bottomMargin'>
                    <div className='line '></div>
                </div>

                <div className='fontSmall row pad justify-btw'>
                    <div>© 2023  All rights reserved. Designed By Codezonic</div>

                    <div className='row fontSmall'>
                        <div >Term & Condition</div>
                        <div style={{paddingLeft:'4vh'}}>Privacy Policy</div>
                    </div>
                </div>
            </div>
  )
}

export default SubscribeComp