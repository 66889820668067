import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      style={{ ...style, display: "block", background: "red", position: 'relative', bottom: '30px', right: '100px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}

const generateStars = (rating) => {
  const totalStars = 5;
  const stars = [];
  for (let i = 0; i < totalStars; i++) {
    if (i < rating) {
      stars.push(<span key={i} className="star filled">&#9733;</span>);
    } else {
      stars.push(<span key={i} className="star">&#9734;</span>);
    }
  }
  return stars;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  return `${day} ${month}`;
};

const TestimonialSlider = () => {
  const URL = process.env.REACT_APP_API_URL;
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); 
    fetch(`${URL}/getTestimonials`)
      .then(response => response.json())
      .then((data) => {
        console.log('API response:', data.testimonials);
        setReviews(data.testimonials);
      })
      .catch(error => console.error('Error fetching data:', error))
      .finally(() => setLoading(false)); 
  }, [URL]); 

  let sliderRef = useRef(null);
  const next = () => sliderRef.slickNext();
  const previous = () => sliderRef.slickPrev();

  var settings = {
    className: 'sws',
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  };

  return (
    <div>
      <div className='pad' style={{ backgroundColor: '#fbfbfb', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div className='subHeading'>testimonial</div>
          <div className='headingAboutus'>What Our Clients Say About Us</div>
        </div>
        <div style={{ textAlign: "end", display: 'flex', justifyContent: 'center', alignItems: 'center' }} className=''>
          <button className="buttonForward" onClick={previous}>
            <FontAwesomeIcon icon={faChevronLeft} color='white' size='2x' />
          </button>
          <button className="buttonForward" onClick={next}>
            <FontAwesomeIcon icon={faChevronRight} color='white' size='2x' />
          </button>
        </div>
      </div>

      {loading ? (
        <div className="sweet-loading" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ClipLoader color={"#000000"} loading={loading} size={50} />
        </div>
      ) : (
        <div className="slider-container">
          <Slider
            className='slider'
            ref={slider => {
              sliderRef = slider;
            }}
            {...settings}>

            {reviews.map((review) => (
              <div key={review.id}>
                <div className="review-card">
                  <div className='row justify-btw'>
                    <div className='row'>
                      <img src={review.image} alt={review.name} className="review-image" />
                      <h3 style={{ paddingLeft: '30px' }}>{review.name}</h3>
                    </div>
                    <img src='assets/Quotes.webp' className='quotes' alt="Quotes" />
                  </div>
                  <p style={{ fontSize: 16, color: '#8F8F8F', fontWeight: 400 }}>{review.details}</p>
                  <div className="review-footer">
                    <div className="review-rating">
                      {generateStars(review.ratings)}
                    </div>
                    <span className="review-date">{formatDate(review.date)}</span>
                  </div>
                </div>
              </div>
            ))}

          </Slider>
        </div>
      )}
    </div>
  );
};

export default TestimonialSlider;
