import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SubscribeComp from '../../components/SubscribeComp';
import TestimonialSlider from '../../components/TestimonialSlider';
import { useNavigate } from 'react-router';


const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  return { day, month };
};

function Home() {

  const [selected, setSelected] = useState('home');
  const [selectedService, setSelectedService] = useState(1);
  const [selectedCard, setSelectedCard] = useState(null);
  const [openSection, setOpenSection] = useState(null);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${URL}/getCaseStudy`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log('helllooooooo', data.caseStudies);
        setData(data.caseStudies);
      })
      .catch((error) => {
        console.log('Error fetching data:', error);
      })
  }, [])

  const handleSelect = (item) => {
    setSelected(item);
  };


  const handleServiceClick = (service) => {
    setSelectedService(service === selectedService ? null : service);
  };



  const handleCardClick = (index) => {
    setSelectedCard(index);
  };


  const handleToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };


  const cardData = [
    {
      id: 1,
      title: "Financial Planning",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      icon: "assets/icons/financial-planning.webp"  // replace with the actual path to your icon
    },
    {
      id: 2,
      title: "Business Growth Plan",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      icon: "assets/icons/business-growth.webp"  // replace with the actual path to your icon
    },
    {
      id: 3,
      title: "Tax Management Plan",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      icon: "assets/icons/tax-management.webp"  // replace with the actual path to your icon
    },
    {
      id: 4,
      title: "Best Marketing Analysis",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      icon: "assets/icons/marketing-analysis.webp"  // replace with the actual path to your icon
    },
    {
      id: 5,
      title: "Estate Planning",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      icon: "assets/icons/estate-planning.webp"  // replace with the actual path to your icon
    },
    {
      id: 6,
      title: "Investment Management",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      icon: "assets/icons/investment-management.webp"  // replace with the actual path to your icon
    }
  ];


  const services = [
    { id: '01', title: 'Strategic Business Dev', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
    { id: '02', title: 'Global Corporate Liaison', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
    { id: '03', title: 'Strategic Investments', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
    { id: '04', title: 'Custom Strategies Dev', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
  ];


  const renderCard = (card, index) => (
    <div
      key={index}
      className='card1'
      style={{
        flex: 0.3, backgroundColor: selectedCard === index ? ' #02273A' : '#F9F9F9',
        position: 'relative',  // Add relative positioning
        padding: '20px',       // Optional padding for better spacing
        borderRadius: '10px'   // Optional for rounded corners
      }}
      onClick={() => handleCardClick(index)}
    >
      <div className='circle' style={{ backgroundColor: selectedCard === index ? 'white' : ' #02273A' }}>
        <img
          className='imageCenter'
          src={selectedCard === index
            ? `/assets/financialLogo/black${index + 1}.svg`
            : `/assets/financialLogo/white${index + 1}.svg`}
        />
      </div>
      <div style={{ fontSize: 31, fontWeight: 500, paddingBottom: 10, paddingTop: 10, color: selectedCard === index ? 'white' : ' #02273A' }}>{card.title}</div>
      <div style={{ fontSize: 17, fontWeight: 400, color: selectedCard === index ? 'white' : ' #8f8f8f' }}>{card.description}</div>
      {selectedCard === index && <div className='readmore' style={{ color: selectedCard === index ? ' white' : '#02273A', paddingRight: '4px' }} >Read more <img style={{ paddingLeft: 20 }} src='/assets/arrowReadmore.svg' /></div>}


    </div>
  );


  return (
    <div className='outfit'>

      <div className='oo'>
        <img className='img-responsive' src='/assets/man.webp' />
      </div>
      <div className='div2 row pad justify-btw' style={{ flex: 1 }}>
        <div className='col box homeHeadingDiv' >
          <div className='hieght200'></div>
          <div className='subHeading bottomMargin'>be smart & creative</div>
          <div className='heading bottomMargin paddingB'>Strategic Consultancy Solutions Tailored Just for You!</div>
          <div className='gray-txt paddingB'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text.Lorem Ipsum has been the industry's standard dummy </div>
          <div>
            <button className='btn-blue '>Get Started</button>
          </div>
        </div>

        <div className='row imageMan' style={{ flex: 0.4 }}>
          <div>
            <img className='redStar' src='/assets/starRed.webp' />
          </div>

          <div>
            <img className='blueStar' src='/assets/starBlue.webp' />
          </div>
          <div className='imageMan'>
            <img class='responsive-img' src='/assets/man.webp' />
          </div>
          <div>
            <img className='blueStar2' src='/assets/starBlue.webp' />
          </div>
        </div>

      </div>

      <div className='row' style={{ backgroundColor: '#02273A', padding: 51 }}>
        <div className='bannertext paddingL'>100+ <span className='underline'>Trusted Partner</span></div>

      </div>

      <div className='aboutusMainDiv pad' style={{ flex: 1, }}>
        <div style={{ flex: 0.6 }}>
          <p className='subHeading'>About us</p>
          <p className='headingAboutus'>Let us partner with you to transform your financial</p>
          <img src='/assets/image2.webp' className='responsive-img' />
        </div>

        {/* sectionnnnnnnnn */}

        <div style={{ flex: 0.4 }} className='new'>
          <div
            className={`new3 ${openSection === 1 ? 'open' : ''}`}
            style={{ display: 'flex', alignItems: 'center', marginTop: '5vh', backgroundColor: openSection === 1 ? '#02273A' : '#f9f9f9' }}
            onClick={() => handleToggle(1)}
          >
            <div className='new2' style={{ backgroundColor: openSection === 1 ? '#02273A' : '#f9f9f9', color: openSection === 1 ? 'white' : '#02273A' }}>
              <p className='textbold'>10+</p>
              <p className='textbold2'>Years of experience</p>
              <img
                src={openSection === 1 ? '/assets/arrowDown.webp' : '/assets/arrowRight.webp'}

              />
            </div>
            {openSection === 1 && (
              <div className='padding paddingtext' style={{ color: openSection === 1 ? 'white' : '#02273A' }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s.
              </div>
            )}
          </div>

          <div
            className={`new3 ${openSection === 2 ? 'open' : ''}`}
            style={{ display: 'flex', alignItems: 'center', marginTop: '5vh', backgroundColor: openSection === 2 ? '#02273A' : '#f9f9f9' }}
            onClick={() => handleToggle(2)}
          >
            <div className='new2' style={{ backgroundColor: openSection === 2 ? '#02273A' : '#f9f9f9', color: openSection === 2 ? 'white' : '#02273A' }}>
              <div className='textbold'>18+</div>
              <p className='textbold2'>Happy Customers</p>
              <img
                src={openSection === 2 ? '/assets/arrowDown.webp' : '/assets/arrowRight.webp'}
              />
            </div>
            {openSection === 2 && (
              <div className='padding paddingtext' style={{ color: openSection === 2 ? 'white' : '#02273A' }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s.
              </div>
            )}
          </div>

          <div
            className={`new3 ${openSection === 3 ? 'open' : ''}`}
            style={{ display: 'flex', alignItems: 'center', marginTop: '5vh', backgroundColor: openSection === 3 ? '#02273A' : '#f9f9f9' }}
            onClick={() => handleToggle(3)}
          >
            <div className='new2' style={{ backgroundColor: openSection === 3 ? '#02273A' : '#f9f9f9', color: openSection === 3 ? 'white' : '#02273A' }}>
              <div className='textbold'>10+</div>
              <p className='textbold2'>Creative Professionals</p>
              <img
                src={openSection === 3 ? '/assets/arrowDown.webp' : '/assets/arrowRight.webp'}
              />
            </div>
            {openSection === 3 && (
              <div className='padding paddingtext' style={{ color: openSection === 3 ? 'white' : '#02273A' }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                ever since the 1500s.
              </div>
            )}
          </div>
        </div>

      </div>

      {/*.............. WHO we ARE ............. */}


      <div className='whoWeAre' style={{ marginTop: '2vh' }}>
        <div style={{ backgroundImage: "url(assets/image3.webp)" }}>

          <div className='whoWeAre'>

            <div className='weAreHeading'>Who we are?</div>
            <div className='whoWeAreText'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy textLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy textLorem Ipsum is simply
              dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the
              1500s Lorem Ipsum is simply dummy text</div>

            <div className='whoWeAreText'>Lorem Ipsum is simply dummy text
              of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s Lorem Ipsum is
              simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s
              Lorem Ipsum is simply dummy text.
            </div>
          </div>
        </div>
      </div>


      <div className='whiteText pad'>
        <div className='blueBackground featureServiceMain pad'>
          <div style={{ flex: 0.4 }}>
            <div style={{ fontSize: 20, fontWeight: 500, textTransform: 'capitalize' }}>Loreum Ipsum</div>
            <div className='font50'>Featured Services</div>

            {services.map((service) => (
              <div key={service.id} className='service' onClick={() => handleServiceClick(service.id)}>
                <div className={`servicesHeading ${selectedService === service.id ? 'selectedService' : ''}`} >
                  <span className={`serviceId ${selectedService === service.id ? 'selectedService' : ''}`}>{service.id}</span>
                  <span className={`serviceTitle ${selectedService === service.id ? 'selectedService' : ''}`}>{service.title}</span>
                </div>
                {selectedService === service.id && (
                  <div className='serviceDescription'>
                    {service.description}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div style={{ flex: 0.6 }} className='pad'>
            <img className='paddingBottom img-responsive' src='assets/featuredServices.webp' alt='Featured Services' />
            <div className='featureServiceRight justify-btw'>
              <div className='txt'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing...
              </div>
              <button className='redBtn'>View More</button>
            </div>
          </div>
        </div>
      </div>


      <div className='pad display1000'>
        <div className='subHeading'>Practice areas</div>
        <div className='headingAboutus'>Finances with expert Financial consulting</div>

        <div className='' style={{ display: 'flex', alignItems: 'center' }}>
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
              1000: {
                slidesPerView: 1,
              },
            }}
          >
            {cardData.map((card, index) => (
              <SwiperSlide key={index}>
                {renderCard(card, index)}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>


      <div className='none pad'>
        <div className='subHeading'>Practice areas</div>
        <div className='headingAboutus'>Finances with expert Financial consulting</div>

        <div className=' praticeAreaMain justify-btw' style={{ flex: 1, marginBottom: 20 }}>
          {cardData.slice(0, 3).map((card, index) => (
            <div
              key={index}
              className='card1'
              style={{
                flex: 0.3, backgroundColor: selectedCard === index ? ' #02273A' : '#F9F9F9',
                position: 'relative',  // Add relative positioning
                padding: '20px',       // Optional padding for better spacing
                borderRadius: '10px'   // Optional for rounded corners
              }}
              onClick={() => handleCardClick(index)}
            >
              <div className='circle' style={{ backgroundColor: selectedCard === index ? 'white' : ' #02273A' }}>
                {/* <img className='imageCenter' src='/assets/Vector.webp' /> */}
                <img
                  className='imageCenter'
                  src={selectedCard === index
                    ? `/assets/financialLogo/black${index + 1}.svg`
                    : `/assets/financialLogo/white${index + 1}.svg`}
                />
              </div>
              <div style={{ fontSize: 31, fontWeight: 500, paddingBottom: 10, paddingTop: 10, color: selectedCard === index ? 'white' : ' #02273A' }}>{card.title}</div>
              <div style={{ fontSize: 17, fontWeight: 400, color: selectedCard === index ? 'white' : ' #8f8f8f' }}>{card.description}</div>
              {selectedCard === index && <div className='readmore' style={{ color: selectedCard === index ? ' white' : '#02273A', paddingRight: '4px' }} >Read more <img style={{ paddingLeft: 20 }} src='/assets/arrowReadmore.svg' /></div>}

            </div>
          ))}
        </div>

        <div className='none praticeAreaMain justify-btw' style={{ flex: 1 }}>
          {cardData.slice(3).map((card, index) => (
            <div
              key={index + 3}
              className='card1'
              style={{
                flex: 0.3, backgroundColor: selectedCard === index + 3 ? ' #02273A' : '#F9F9F9',
                position: 'relative',  // Add relative positioning
                padding: '20px',       // Optional padding for better spacing
                borderRadius: '10px'   // Optional for rounded corners
              }}
              onClick={() => handleCardClick(index + 3)}
            >
              <div className='circle' style={{ backgroundColor: selectedCard === index + 3 ? 'white' : ' #02273A' }}>
                <img
                  className='imageCenter'
                  src={selectedCard === index + 3
                    ? `/assets/financialLogo/black${index + 3}.svg`
                    : `/assets/financialLogo/white${index + 3}.svg`}
                />
              </div>
              <div style={{ fontSize: 31, fontWeight: 500, paddingBottom: 10, paddingTop: 10, color: selectedCard === index + 3 ? 'white' : ' #02273A' }}>{card.title}</div>
              <div style={{ fontSize: 17, fontWeight: 400, color: selectedCard === index + 3 ? 'white' : ' #8f8f8f' }}>{card.description}</div>
              {selectedCard === index + 3 && <div className='readmore' style={{ color: selectedCard === index + 3 ? ' white' : '#02273A', paddingRight: '4px' }} >Read more <img style={{ paddingLeft: 20 }} src='/assets/arrowReadmore.svg' /></div>}


            </div>
          ))}
        </div>
      </div>


      <div className='pad display1000'>
        <div className='subHeading'>Explore</div>
        <div className='headingAboutus'>Our Case Studies</div>

        <Swiper
          spaceBetween={20}
          // slidesPerView={1}
          breakpoints={{
            1000: {
              slidesPerView: 1,
            },
          }}
          className="cards-container"
        >
          {data.map(card => {
            const formattedDate = formatDate(card.date);
            return (
              <SwiperSlide key={card.id}>
                <div className="card">
                  <img src={card.image} alt={card.title} className="card-image" />
                  <div className="caseStudy">
                    <div className='row justify-btw'>
                      <div className="subHeading">{card.category}</div>
                      {/* <div className="subHeading">{card.category.join(' ')}</div> */}
                      <span className="date-badge center2">
                        <div className="date-day">{formattedDate.day}</div>
                        <div className="date-month">{formattedDate.month}</div>
                      </span>
                    </div>

                    <div style={{ marginTop: -40 }}>
                      <div className="caseStudyTitle">{card.sub_category}</div>
                      <div className="cStudyText">{card.details}</div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <div className="more">Read More</div>
                        <img src='/assets/readMoreArrow.webp' alt="Read More Arrow" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>


      <div className='pad none'>
        <div className='subHeading'>Explore</div>
        <div className='headingAboutus'>Our Case Studies</div>

        <div className="cards-container " style={{ overflow: 'hidden' }}>
          {data.map(card => {
            const formattedDate = formatDate(card.date);
            return (
              <div className="card">
                  <img src={card.image} alt={card.title} className="card-image" />
                  <div className="caseStudy">
                    <div className='row justify-btw'>
                      <div className="subHeading">{card.category}</div>
                      {/* <div className="subHeading">{card.category.join(' ')}</div> */}
                      <span className="date-badge center2">
                        <div className="date-day">{formattedDate.day}</div>
                        <div className="date-month">{formattedDate.month}</div>
                      </span>
                    </div>

                    <div style={{ marginTop: -40 }}>
                      <div className="caseStudyTitle">{card.sub_category}</div>
                      <div className="cStudyText">{card.details}</div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                        <div className="more">Read More</div>
                        <img src='/assets/readMoreArrow.webp' alt="Read More Arrow" />
                      </div>
                    </div>
                  </div>
                </div>
            );
          })}
        </div>
      </div>

      <div className='pad btnEnd'><button className='btnEnd btn-blue' onClick={() => navigate('/case-studies')}>Explore More</button></div>
      <TestimonialSlider />
      <SubscribeComp />
    </div>
  )
}

export default Home